<template>
    <div class="up_content_inner up_billing_details">
        <div class="up_main_container">
            <div class="up_page_header p-mb-4">
                <h2 class="up_page_title p-mr-6">Billing Details</h2>
            </div>
            <div class="up_inner_container">
                <!-- Billing screen component call Start -->
                <Billingscreen :onClicked="fetchCards" />
                <!-- Billing screen component call End -->
            </div>
            <div class="billing-list_heading">
            <h3>Saved Card(s):</h3>
            </div>
            <!-- <div :class="['up_payment_card_wrap p-mt-5', cardData.loading ? 'loading pi-spinner' : '']">
                <div class="up_payment_card" v-for="card of cardData.data" :key="card.uuid">
                    <div :class="['up_card_details', card.isPrimary ? 'up_card_selected' : '', card.isExpired ? 'up_card_expired' : '']" :data-name="card.isPrimary ? 'Primary' : ''">
                            <div class="card-brand">
                                {{card.brand}} <span>Primary</span>
                            </div>
                            <div class="card_no_show">
                                <span class="heading_for_cardno">Card No</span>
                                <div class="cardlast_no">**** **** **** {{card.last4}}</div>
                            </div>
                            <div class="card_no_show">
                                <span class="heading_for_cardno">Valid TO</span>
                                <div class="cardlast_no">{{card.exp_month}}/{{card.exp_year}}</div>
                            </div>
                            <img :src="imageUrlFrontEnd + 'images/' + [card.brand === 'Visa' ? 'visa.jpg' : card.brand === 'MasterCard' ? 'master_card.png' : 'amex.png']" width="60" height="35">
                        
                        
                            <span class="up_card_label">  </span>
                            <span class="up_card_actions">
                                <span v-if="!card.isExpired" class="up_card_primary_set" @click="cardAction(card, 'primarySet')">
                                    <i class="pi pi-check text-success"></i>
                                </span>
                                <span v-else class="up_card_primary_set" @click="cardAction(card, 'edit')">
                                    <i class="pi pi-pencil text-warning"></i>
                                </span>
                                <span class="up_card_primary_delete" @click="cardAction(card, 'delete')">
                                    <i class="pi pi-trash text-danger"></i>
                                </span>
                            </span>
                        
                        
                    </div>
                </div>
            </div> -->
            <div :class="['new_cardtype_list', cardData.loading ? 'loading pi-spinner' : '']">
                <div :class="['new_single_card', card.isPrimary ? 'up_card_selected_wrapper' : '']" v-for="card of cardData.data" :key="card.uuid">
                    <div :class="['card_inner_details', card.isPrimary ? 'up_card_selected' : '', card.isExpired ? 'up_card_expired' : '', card.brand === 'Visa' ? 'visa-bg-wrapper' : card.brand === 'MasterCard' ? 'master-card-wrapper' : 'amex-wrapper' ]" :data-name="card.isPrimary ? 'Primary' : ''">
                        <div class="add_card_type">
                            <div class="left">
                                <div class="card-chip">
                                    <!-- <img :src="imageUrlFrontEnd + 'images/chip-card.png' " width="60" height="35"> -->
                                    <img src="https://truabilities-dev.s3.us-west-1.amazonaws.com/Media/Users/chip-card.png?AWSAccessKeyId=AKIAZJ4VKLIYQDJKT4WU&Expires=1966641878&Signature=HbSgd%2BZmCtxzMWHh3i7OahILs74%3D" >
                                </div>
                            </div>
                            <div class="right">Credit Card</div>
                        </div>
                        
                        <div class="card-number-new">
                          <span>**** **** ****</span> {{card.last4}}
                        </div>
                        <div class="card-with-auth-name">
                            
                            <div class="card-validity">
                                
                                <!-- <span class="heading">MONTH/YEAR</span> -->
                                <span class="validity-data">
                                    <span class="valid-heading">Valid</span> {{card.exp_month}}/{{card.exp_year}}
                                </span>
                                <div class="card-type-logo">
                                    <img :src="imageUrlFrontEnd + 'images/' + [card.brand === 'Visa' ? 'visa.jpg' : card.brand === 'MasterCard' ? 'master_card.png' : 'amex.png']" width="60" height="35">
                                </div>
                            </div>
                        </div>

                        <!-- Card Action --> 
                        <span class="card_card_actions">
                                <span v-if="!card.isExpired && !card.isPrimary" class="up_card_primary_set btn-set" @click="cardAction(card, 'primarySet')">
                                    <i class="pi pi-check text-success"></i>
                                </span>
                                <span class="up_card_primary_set btn-edit" @click="cardAction(card, 'edit')" >
                                    <i class="pi pi-pencil text-warning"></i>
                                </span>
                                <span class="up_card_primary_delete btn-del" @click="cardAction(card, 'delete')" >
                                    <i class="pi pi-trash text-danger"></i>
                                </span>
                        </span>
                    </div>
                </div>
                <!--<div class="new_single_card" >
                    <div class="card_inner_details amex-wrapper">
                        <div class="add_card_type">
                            <img src="amex.png" width="60" height="35">
                        </div>
                        <div class="card-chip">
                            <img :src="imageUrlFrontEnd + 'images/chip-card.png' " width="60" height="35">
                            <img src="https://truabilities-dev.s3.us-west-1.amazonaws.com/Media/Users/chip-card.png?AWSAccessKeyId=AKIAZJ4VKLIYQDJKT4WU&Expires=1966641878&Signature=HbSgd%2BZmCtxzMWHh3i7OahILs74%3D" >
                        </div>
                        <div class="card-number-new">
<span>**** **** ****</span> 561
                        </div>
                        <div class="card-with-auth-name">
                            <span class="cardholder-nameauth">
                                Kartik Ranjan Dasgupta
                            </span>
                            <div class="card-validity">
                                <span class="heading">MONTH/YEAR</span>
                                <span class="validity-data">
                                    11/2026
                                </span>
                            </div>
                        </div>
                        
                    </div>
                </div>-->
            </div>
        </div>
        <!--- Update card exp month Modal --->
        <Dialog v-model:visible="updateCard.open" contentClass="up_custom_content" :style="{width: '500px'}" :modal="true">
            <template #header>
                <span class="p-dialog-title">
                    Update Card - {{updateCard.data.last4}}
                </span>
            </template>
            <div class="up_modal_inner_content">
                <div class="p-fluid p-formgrid p-grid">
                    <div class="p-field p-col">
                        <label for="cdExpMonth">Exp Month</label>
                        <InputText v-model="updateCard.expMonth" id="cdExpMonth" type="text" placeholder="MM" />
                        <small class="p-invalid text-danger" v-if="updateCard.expMonthEmpty">Exp Month is invalid.</small>
                    </div>
                    <div class="p-field p-col">
                        <label for="cdExpYear">Exp Year</label>
                        <InputText v-model="updateCard.expYear" id="cdExpYear" type="text" placeholder="YYYY" />
                        <small class="p-invalid text-danger" v-if="updateCard.expYearEmpty">Exp Year is invalid or expired.</small>
                    </div>
                </div>
            </div>
            <template #footer>
                <Button label="Update" icon="pi pi-check" @click="cardAction(card, 'update')" autofocus />
                <Button label="Cancel" icon="pi pi-times" @click="updateCard.open = false" class="p-button-text"/>
            </template>         
        </Dialog>
        <!--- Update card exp month Modal --->
        <!-- Update and delete confimation dialog Start -->
        <ConfirmDialog></ConfirmDialog>
        <!-- Update and delete confimation dialog End -->
    </div>
</template>

<script>
import { userPortalCardList, userPortalCardUpdate } from '../../../../config/appUrls';
import { apiKey, imageUrlFrontEnd, cryptoSecret } from '../../../../config/constant';
import customerLogout from '../../../../helper/userLogout/customerLogout';
import Billingscreen from '../../views/BillingScreen';
import { useStore } from 'vuex';
import axios from 'axios';
import { reactive, onBeforeMount } from 'vue';
import SimpleCrypto from "simple-crypto-js";
import { useConfirm } from "primevue/useconfirm";

const simpleCrypto = new SimpleCrypto(cryptoSecret);

export default {
    name: "Billing",
    components: {
        Billingscreen
    },
    setup(){
        let store = useStore();

        const cardData = reactive({
            data: null,
            loading: false
        });
        
        //Confirm screen call
        const confirm = useConfirm();

        const updateCard = reactive({
            data: null,
            open: false,
            expMonth: '',
            expYear: '',
            expMonthEmpty: false,
            expYearEmpty: false
        })

        onBeforeMount(()=>{
            fetchCards();
        });

        /**
         * Fetch all cards
         * userPortalCardList - api for card fetching
         * customerLogout() - If any un-authorized error
         */
        const fetchCards = async() => {
            try{
                cardData.loading = !cardData.loading;
                const response = await axios.get( userPortalCardList, {
                    headers: {
                        apiKey: apiKey,
                        token: store.getters.adminAuthToken
                    }
                });
                if (response.data.status === 200) {
                    cardData.data = simpleCrypto.decrypt(response.data.data);
                    console.log(cardData.data)
                    cardData.loading = !cardData.loading;
                }
            }catch(err){
                console.log(err);
                if(err.response.status === 403 || store.getters.adminAuthToken === '' ){
                    customerLogout();
                } else {
                    console.log('error', err);
                }
            }
        };


        /**
         * To delete, edit, update and default card set function
         * Used conditional method on action location
         * After success fetch fetchCards()
         */
        const cardAction = async(data, load) => {
            if (load === 'edit') {
                updateCard.data = data;
                updateCard.open = true;
                updateCard.expMonth = updateCard.data.exp_month;
                updateCard.expYear = updateCard.data.exp_year;
            } else if (load === 'update') {
                if (updateCard.expMonth === '' || updateCard.expMonth.length > 2) {
                    updateCard.expMonthEmpty = true;
                } else {
                    updateCard.expMonthEmpty = false;
                }
                if (updateCard.expYear === '' || updateCard.expYear.length > 4 || updateCard.expYear < new Date().getFullYear()) {
                    updateCard.expYearEmpty = true;
                } else {
                    updateCard.expYearEmpty = false;
                }
                if (!updateCard.expMonthEmpty && !updateCard.expYearEmpty) {
                    const options = {
                        method: 'put',
                        url: userPortalCardUpdate,
                        params: {
                            uuid: updateCard.data.uuid
                        },
                        data: JSON.stringify({
                            payload: simpleCrypto.encrypt({
                                cardExpMonth: updateCard.expMonth,
                                cardExpYear: updateCard.expYear
                            })
                        }),
                        headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                        transformRequest: [(data) => {
                            return data;
                        }]
                    };
                    const response = await axios(options);
                    if (response.status === 200) {
                        updateCard.open = false;
                        fetchCards();
                    }
                }
            } else {
                //This confirm function is for setting card primary and card delete
                confirm.require({
                    message: 'Are you sure about this ?',
                    header: load === 'primarySet' ? 'Set as default' : 'Delete Permanently',
                    icon: load === 'primarySet' ? 'pi pi-check' : 'pi pi-trash',
                    acceptLabel: 'Confirm',
                    rejectLabel: 'Cancel',
                    acceptClass: 'p-button-primary',
                    accept: async() => {
                        const options = {
                            method: load === 'primarySet' ? 'put' : 'delete',
                            url: userPortalCardList,
                            params: {
                                uuid: data.uuid
                            },
                            headers: {'Content-Type': 'application/json', 'apiKey': apiKey, 'token': store.getters.adminAuthToken},
                            transformRequest: [(data) => {
                                return data;
                            }]
                        };
                        const response = await axios(options);
                        if (response.status === 200) {
                            fetchCards();
                        }       
                    }
                });
                
            }
        }

        /**
         * Returns variable to templates
         */
        return{
            updateCard,
            cardData,
            imageUrlFrontEnd,
            cardAction,
            fetchCards
        }
    }
}
</script>

<style>

</style>